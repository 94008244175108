<template>
  <div>
    <b-row class="content-between">
      <b-col md="3" class="col-custom-xxl-auto">
        <div class="header-tablepage">Send All</div>
        <div class="count-style">
          {{ all | numeral("0,0") }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">Sent Successfully</div>
        <div class="count-style">
          {{ success | numeral("0,0") }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">Wait for response</div>
        <div class="count-style">
          {{ wait_for_response | numeral("0,0") }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">Failed To Send</div>
        <div class="count-style">
          {{ fail | numeral("0,0") }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">% Received</div>
        <div class="count-style">
          {{ percen }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">% Conversion</div>
        <div class="count-style">
          {{ conversion_percen }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">Click Rate</div>
        <div class="count-style">
          {{ click_rate }}
        </div>
      </b-col>
      <b-col md="3" class="border-sm-left col-custom-xxl-auto">
        <div class="header-tablepage">Open Rate</div>
        <div class="count-style">
          {{ open_rate }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      required: false,
      type: Number | String,
      default: 0,
    },
    wait_for_response: {
      required: false,
      type: Number | String,
      default: 0,
    },
    fail: {
      required: false,
      type: Number | String,
      default: 0,
    },
    percen: {
      required: false,
      type: String | Number,
      default: 0,
    },
    all: {
      required: false,
      type: Number | String,
      default: 0,
    },
    conversion_percen: {
      required: false,
      type: Number | String,
      default: 0,
    },
    click_rate: {
      required: false,
      type: Number | String,
      default: 0,
    },
    open_rate: {
      required: false,
      type: Number | String,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.count-style {
  font-size: 25px;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 15px;
}
@media screen and (min-width: 415px) {
  ::v-deep .border-sm-left {
    border-left: 1px solid #c5c5c5 !important;
  }
}
</style>
