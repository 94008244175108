<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div class="p-0 b-color-white">
      <div class="b-tranparent-purple p-3">
        <HeaderReport
          :all="headerData.send_all"
          :success="headerData.send_success"
          :wait_for_response="headerData.send_wait_for_response"
          :fail="headerData.send_fail"
          :percen="headerData.send_success_percen"
          :conversion_percen="headerData.conversion_percen"
          :click_rate="headerData.click_rate"
          :open_rate="headerData.open_rate"
        />
      </div>

      <b-row class="p-3">
        <template v-if="lineStatData">
          <b-col> <div class="chart-title-name">Line Statisitcs</div></b-col>
          <b-col cols="12">
            <div class="d-flex">
              <template v-for="(items, index) of lineStatList">
                <div class="box-list" :key="'box-' + index">
                  <div class="title-box">{{ items.name }}</div>
                  <div class="content-box">{{ lineStatData[items.key] }}</div>
                </div>
              </template>
            </div>
          </b-col>
          <template v-if="lineStatData.clicks.length > 0">
            <b-col> <div class="chart-title-name">Click Details</div></b-col>
            <b-col cols="12" class="click-detail mb-3">
              <div class="d-flex">
                <template v-for="(items, index) of clickLabel">
                  <div class="box-header-list mb-0" :key="'box-' + index">
                    <div class="title-box">{{ items.name }}</div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                </template>
              </div>
              <template v-for="(items, index) of lineStatData.clicks">
                <div class="d-flex" :key="'box-' + index">
                  <div class="box-content-list">
                    <div class="title-box">{{ items.seq || "-" }}</div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                  <div class="box-content-list">
                    <div class="title-box">{{ items.url || "-" }}</div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                  <div class="box-content-list">
                    <div class="title-box">{{ items.click || "-" }}</div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                  <div class="box-content-list">
                    <div class="title-box">{{ items.uniqueClick || "-" }}</div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                  <div class="box-content-list">
                    <div class="title-box">
                      {{ items.uniqueClickOfRequest || "-" }}
                    </div>
                    <!-- <div class="content-box">{{ lineStatData[items.key] }}</div> -->
                  </div>
                </div>
              </template>
            </b-col>
          </template></template
        >
        <div class="break-normal"></div>
        <b-col class="text-left" md="8" lg="6" xl="4">
          <b-input-group>
            <b-form-input
              class="search-bar"
              @keydown.enter.prevent="handleSearch"
              placeholder="Search ID, Name"
              v-model="search"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <b-iconstack
                font-scale="2"
                type="submit"
                @click.prevent="handleSearch"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right" md="4" lg="6" xl="8">
          <button
            type="button"
            class="btn button btn-mobile ml-2"
            @click.prevent="toggleModal"
            :disabled="disableBtnExport"
          >
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-col>
      </b-row>
      <TabListOverview
        :filter="filter"
        :userData="userData"
        :fields="fields"
        :isBusy="isBusy"
        :showingTo="showingTo"
        :rows="rows"
        @chkTabIndex="handleTabsChange"
        @changePage="handleChangePage"
        @getDataInPagination="getDataInPagination"
      />
      <SidebarFilter ref="sidebarFilter" />
      <ModalInputEmail
        ref="ModalInputEmail"
        :form="formExport"
        @closeModal="clearExportFilter"
        @changeEmail="(val) => (formExport.email = val)"
        @submit="exportExcel"
      />
    </div>
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderReport from "@/components/automation/segmentation/HeaderReport";
import TabListOverview from "@/components/automation/segmentation/TabListOverview";
import SidebarFilter from "@/components/automation/segmentation/SidebarFilter";
export default {
  components: {
    OtherLoading,
    HeaderReport,
    TabListOverview,
    SidebarFilter,
    ModalInputEmail,
  },
  props: {
    userData: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    rows: {
      required: false,
      type: Number,
    },
    type: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    headerData: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      chkTab: 0,
      search: "",
      disableBtnExport: false,
      formExport: {
        email: "",
        segmentation_id: this.id,
      },
      lineStatList: [
        { key: "numberOfRecipeints", name: "Number Of Recipients" },
        { key: "numberOfOpenings", name: "Number Of Openings" },
        { key: "openingRate", name: "Opening Rate", isPercent: true },
        { key: "numberOfUrltaps", name: "Number Of URL Taps" },
        {
          key: "numberOfUrlTapRate",
          name: "Number Of URL Taps Rate",
          isPercent: true,
        },
      ],
      lineStatData: null,
      clickLabel: [
        { key: "seq", name: "SEQ." },
        { key: "url", name: "URL" },
        { key: "click", name: "Click" },
        { key: "uniqueClick", name: "Unique Click" },
        { key: "uniqueClickOfRequest", name: "Unique Click Of Request" },
      ],
    };
  },
  watch: {
    userData(val) {
      if (val.line_count > 0) this.getLineStatics();
    },
  },
  mounted() {},
  methods: {
    async getLineStatics() {

      var res = null;
      if (this.type === "automation") {
        res = await this.axios(`/MarketingAutomation/linestat/${this.id}`);
      } else {
        res = await this.axios(`/message/linestat/${this.id}`);
      }
      // res.data.detail.clicks = [
      //   {
      //     seq: 1,
      //     url: "https://developers.line.biz/",
      //     click: 35,
      //     uniqueClick: 25,
      //     uniqueClickOfRequest: null,
      //   },
      //   {
      //     seq: 1,
      //     url: "https://developers.line.biz/",
      //     click: 35,
      //     uniqueClick: 25,
      //     uniqueClickOfRequest: null,
      //   },
      // ];
      this.lineStatData = res.data.detail;
    },
    handleSearch() {
      this.filter.search = this.search;

      this.$emit("searchUserRegister");
    },
    handleChangePage() {
      this.$emit("searchUserRegister");
    },
    handleTabsChange(val) {
      this.filter.channel = val;
      this.$emit("searchUserRegister");
    },
    async exportExcel() {
      this.disableBtnExport = true;
      if (this.type === "automation") {
        this.$bus.$emit("showLoading");
        await this.$store.dispatch("exportAutomationUser", this.id);
        let data = this.$store.state.automation.respExportAutomation;
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `MessageLog-` + dateExcel + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.disableBtnExport = false;
        this.$bus.$emit("hideLoading");
      } else {
        this.$bus.$emit("showLoading");
        await this.$store.dispatch("exportSegmentationUser", this.formExport);
        this.successAlert(
          "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
        );
        this.disableBtnExport = false;
        this.$bus.$emit("hideLoading");
      }
    },
    toggleModal() {
      if (this.type === "automation") this.exportExcel();
      else this.$refs.ModalInputEmail.show();
    },
    getDataInPagination(value) {
      this.$emit("getDataInPagination", value);
    },
    clearExportFilter() {
      this.formExport = {
        email: "",
        segmentation_id: this.id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.b-tranparent-purple {
  background-color: var(--theme-secondary-color);
}
.b-color-white {
  background-color: #fff;
}
.box-list {
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-bottom: 1px solid #d8dbe0;
  margin: 1rem 0;
  .content-box {
    padding: 0.75rem 0.5rem;
  }
  .title-box {
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
    background-color: var(--theme-secondary-color);
    font-weight: bold;
    padding: 0.75rem 0.5rem;
  }
}
.box-header-list {
  .title-box {
    background-color: var(--theme-secondary-color);
  }
}

.box-content-list,
.box-header-list {
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  // width: 20%;
  .content-box {
    padding: 0.75rem 0.5rem;
  }
  .title-box {
    border-bottom: 1px solid #d8dbe0;
    // border-top: 1px solid #d8dbe0;
    // background-color: var(--theme-secondary-color);
    font-weight: bold;
    padding: 0.75rem 0.5rem;
  }
}
</style>
